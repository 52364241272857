import image1 from "../Assets/21.webp";
import cnc from "../Assets/3.jpg";
import Engineering from "../Assets/27.webp";
import others from "../Assets/34.webp";

export const homeServiceList = [
  { id: 1, servicesName: "Mechanical Design", image: image1 },
  { id: 2, servicesName: "CNC Programming", image: cnc },
  { id: 3, servicesName: "Engineering Documentation", image: Engineering },
  { id: 4, servicesName: "Other Services", image: others }
];
